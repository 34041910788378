







































































































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  DataDictionaryDtoPagedResultDto,
  UserTaskCreateOrUpdateDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";

@Component({
  components: {
    AbSelect,
    MultipleUploadFile,
  },
})
export default class CreateJob extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId?: number = 0;
  form: UserTaskCreateOrUpdateDto = {
    id: 0,
  };
  importantLevel: any = [];
  userList: any[] = [];
  btnDisabled = false;

  get hostTypeFile() {
    return AttachmentHostType.UserTask;
  }

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      api.userTask.get({ id: this.dataId }).then((res) => {
        this.form = { ...res };
        // if (this.form.permissionUserId) {
        //   this.form.permissionUserId = String(this.form.permissionUserId)
        // }
      });
    }
    this.fetchDataDictionary();
    this.fetchUserList();
  }

  async fetchUserList() {
    await api.fundUser.getFundUserList({}).then((res: any) => {
      this.userList = res ?? [];
      this.userList.forEach((item: any) => {
        item.value = Number(item.value);
      });
    });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ImportantLevel",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.importantLevel = res.items;
      });
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.form.permissionUserId = Number(this.form.permissionUserId);
        if (this.form!.id) {
          await api.userTask.update({
            body: this.form,
          });
        } else {
          await api.userTask.create({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success("操作成功");
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  get pageHeaderTitle() {
    if (this.dataId) {
      return "修改任务";
    } else {
      return "新增任务";
    }
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    title: [
      {
        required: true,
        message: "任务名称必填",
        trigger: "blur",
      },
    ],
    importantLevelId: [
      {
        required: true,
        message: "紧急程度必填",
        trigger: "blur",
      },
    ],
    permissionUserId: [
      {
        required: true,
        message: "指派给必填",
        trigger: "blur",
      },
    ],
    startTime: [
      {
        required: true,
        message: "开始时间必填",
        trigger: "blur",
      },
    ],
    endTime: [
      {
        required: true,
        message: "结束时间必填",
        trigger: "blur",
      },
    ],
    content: [
      {
        required: true,
        message: "任务内容必填",
        trigger: "blur",
      },
    ],
  };
}
